<div class="box">
  <div class="year-month prizm-font-input-text-14px">
    <div class="month-box">
      <button
        class="pagination-btn"
        [attr.testId]="'prizm-primitive-year-month-pagination__year-button'"
        [class.active]="monthActive"
        [prizmFocusable]="true"
        [disabled]="oneMonth"
        (click)="onMonthClick($event)"
        type="button"
      >
        {{ value | prizmMonth | async }}

        <prizm-icon *ngIf="!oneMonth" iconClass="chevrons-dropdown-small"></prizm-icon>
      </button>
    </div>

    <div class="year-box">
      <button
        class="pagination-btn"
        [attr.data-testid]="'prizm-primitive-year-month-pagination__year-button'"
        [prizmFocusable]="true"
        [class.active]="yearActive"
        [disabled]="oneYear"
        (click)="onYearClick($event)"
        type="button"
      >
        {{ value.formattedYear }}
        <prizm-icon *ngIf="!oneYear" iconClass="chevrons-dropdown-small"></prizm-icon>
      </button>
    </div>
  </div>
  <div>
    <prizm-primitive-spin-button
      [class.hidden]="hideButtons"
      [focusable]="false"
      [leftDisabled]="prevMonthDisabled"
      [rightDisabled]="nextMonthDisabled"
      (leftClick)="onPrevMonthClick()"
      (rightClick)="onNextMonthClick()"
    >
    </prizm-primitive-spin-button>
  </div>
</div>
