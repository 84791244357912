<button class="chips-list__item" [disabled]="disabled" [class.chips-list__item_selected]="selected">
  <div
    class="chips-name"
    #div
    [prizmHint]="hintText"
    [prizmHintHost]="el.nativeElement"
    [prizmHintDirection]="hintDirection"
    [prizmHintCanShow]="
      (!!hintText && (div | prizmCallFunc : prizmIsTextOverflow$ : hintCanShow : false : hintText | async))!
    "
  >
    <ng-content></ng-content>
  </div>
  <button
    class="chips-cancel prizm-icon cancel-close-small"
    *ngIf="deletable"
    [disabled]="disabled"
    (click)="deleted.emit($event)"
  ></button>
</button>
