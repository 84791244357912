<div class="container">
  <div class="control" [class.control_active]="isLeftBtnActive">
    <button
      [icon]="'arrows-chevron-left'"
      (click)="decrease()"
      prizmIconButton
      appearanceType="ghost"
      appearance="secondary"
      size="m"
    ></button>
    <prizm-dropdown-host
      class="dropdown-host"
      #dropdownMore="prizm-dropdown-host"
      *ngIf="canShowMenu && !isRightBtnActive"
      [(isOpen)]="openLeft"
      [content]="dropdown"
      (prizmAfterViewInit)="tabsMoreDropdown = dropdownMore"
      prizmDropdownHostWidth="auto"
    >
      <button
        [icon]="'settings-more-vert'"
        (click)="openLeft = !openLeft"
        prizmIconButton
        appearanceType="ghost"
        appearance="secondary"
        size="m"
      ></button>
    </prizm-dropdown-host>
  </div>

  <div class="tabs" #tabsContainer>
    <ng-content></ng-content>
  </div>

  <div class="control" *ngIf="canShowMenu" [class.control_active]="isRightBtnActive">
    <prizm-dropdown-host
      class="dropdown-host"
      #tabsDropdown
      [(isOpen)]="openRight"
      [content]="dropdown"
      (prizmAfterViewInit)="tabsMoreDropdown = tabsDropdown"
      prizmDropdownHostWidth="auto"
    >
      <button
        [icon]="'settings-more-vert'"
        (click)="openRight = !openRight"
        prizmIconButton
        appearanceType="ghost"
        appearance="secondary"
        size="m"
      ></button>
    </prizm-dropdown-host>
    <button
      [icon]="'arrows-chevron-right'"
      (click)="increase()"
      prizmIconButton
      appearanceType="ghost"
      appearance="secondary"
      size="m"
    ></button>
  </div>

  <ng-template #dropdown>
    <prizm-data-list>
      <div class="tabs-column prizm-tab-menu-container" *prizmLet="tabElements | prizmIconTabs as hasIcons">
        <ng-container *ngFor="let tabElement of tabElements; let i = index">
          <ng-container *ngIf="tabElement as tab">
            <prizm-listing-item
              [disabled]="$any(tab.disabled)"
              [selected]="activeTabIndex === i"
              (click)="clickTab(i)"
            >
              <ng-container *ngIf="tab.icon || hasIcons" leftBox>
                <ng-container *ngIf="tab.icon as icon; else placeholder">
                  <ng-container *polymorphOutlet="icon; context: $any({ idx: i, tab: tab })">
                    <prizm-icon class="icon icon__mark" [size]="16" [iconClass]="$any(icon)"> </prizm-icon>
                  </ng-container>
                </ng-container>
                <ng-template #placeholder>
                  <div class="icon-placeholder"></div>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="tab.content as content">
                <ng-container *polymorphOutlet="content; context: $any({ idx: i, tab: this })">
                  <div
                    class="tab-content"
                    #elem
                    [prizmHint]="content"
                    [prizmHintCanShow]="$any(elem | prizmCallFunc : prizmIsTextOverflow$ | async)"
                  >
                    {{ content }}
                  </div>
                </ng-container>
              </ng-container>

              <ng-container rightBox>
                <prizm-counter
                  *ngIf="tab.count"
                  [value]="tab.count"
                  [status]="tab._counterOptions.status"
                  [disabled]="tab._counterOptions.disabled"
                  [maxValue]="tab._counterOptions.maxValue"
                ></prizm-counter>
                <prizm-icon
                  class="icon"
                  [class.hidden]="!tab.closable"
                  [iconClass]="'cancel-close'"
                  [size]="16"
                  (click)="closeTab(tabElement.idx!)"
                ></prizm-icon>
              </ng-container>
            </prizm-listing-item>
          </ng-container>
        </ng-container>
      </div>
    </prizm-data-list>
  </ng-template>
</div>
